var jQuery = require('jquery');
require('jquery.easing');

window.jQuery = jQuery;
window.$ = jQuery;

// const feather = require('feather-icons');

// require('moment');
// import * as Popper from "@popperjs/core"
// require('bootstrap-five');
// require('bootstrap-five/js/dist/alert');
// require('bootstrap-five/js/dist/button');
// require('bootstrap-five/js/dist/carousel');
// require('bootstrap-five/js/dist/util');
// require('bootstrap-five/js/dist/collapse');
// require('bootstrap/js/dist/dropdown');
// require('bootstrap-five/js/dist/modal');
// require('bootstrap-five/js/dist/popover');
// require('bootstrap-five/js/dist/scrollspy');
// require('bootstrap-five/js/dist/tab');
// require('bootstrap/js/dist/tooltip');

// import * as bootstrap from 'bootstrap';
// import { Tooltip, Toast, Popover } from 'bootstrap';


// feather.replace();

// DOM Ready functions using custom async function so it only funs when jQuery has been fully loaded
$(function() {

    // swiper
    var swiper = new Swiper(".latSwiper", {
        effect: "cards",
        grabCursor: true,
        autoplay: {
            delay: 2000
        },
        perSlideOffset: 30,
        perSlideRotate: 20,
    });
});